import React from "react";

import Page from "lib/@cms/components/layout/Page";

function MortgageApplicationsPage() {
  return (
    <Page pathname="mortgage-applications">
      {() => {
        return (
          <div className="tw-container tw-py-8">
            <iframe
              title="New Mortgage"
              frameBorder="0"
              height="900"
              width="100%"
              marginHeight="0"
              marginWidth="0"
              src="https://secure.web-loans.com/secure.aspx?p=7&amp;u=avistacorporation.lenderplanet.com"
              id="CMS_iframe_1"
              scrolling="yes"
            >
              <p>This application requires iframes enabled</p>
            </iframe>
          </div>
        );
      }}
    </Page>
  );
}

export default MortgageApplicationsPage;
